import Vue from 'vue'
import Toasted from 'vue-toasted'

import App from './App.vue'
import router from './router'
import './fa.config.js'
import './styles/main.scss'

Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 3000
})

Vue.config.productionTip = false

window.cardCache = []
const cards = require.context('@/assets/cards', false, /.*\.jpg$/)
cards.keys().forEach(path => {
  const img = new Image()
  img.src = require(`@/assets/cards/${path.substr(2)}`)
  window.cardCache.push(img)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
